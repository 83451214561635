<template>
  <div id="admin-panel">
    <div></div>
    <Header active_tab="Home"></Header>
    <div class="row">
      <div class="col">
        <SmallCard
          address="/assessment/1/"
          link="icons8-student"
          title="Quick Assessment"
        />
      </div>
      <div class="col">
        <SmallCard
          address="/assessment/2/"
          link="icons8-student"
          title="Standard Assessment"
        />
      </div>
      <div class="col">
        <SmallCard
          address="/assessment/3/"
          link="icons8-student"
          title="Comprehensive Assessment"
        />
      </div>
       <div class="col">
        <SmallCard
          address="schedule"
          link="ant-design:field-time-outlined"
          title="Schedule Making"
        />
      </div>
    </div>
    <div class="row">
     
      <div class="col">
        <SmallCard
          address="revision"
          link="bx:bx-book-bookmark"
          title="Revision"
        />
      </div>
      <div class="col">
        <SmallCard
          address="add-user"
          link="ant-design:user-add-outlined"
          title="Add Users"
        />
      </div>
      <div class="col">
        <SmallCard
          address="/questionbank/"
          link="bi-piggy-bank"
          title="QuestionBank"
        />
      </div>
      <div class="col">
        <SmallCard
          address="/demo-main/"
          link="bi-question"
          title="Preview Test"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import store from "../store";
import { defineAsyncComponent } from "vue";
const SmallCard = defineAsyncComponent(() =>
  import("@/components/SmallCard.vue")
);
const Header = defineAsyncComponent(() => import("@/components/Header"));

export default {
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  components: {
    SmallCard,
    Header
  },
  async mounted() {
    store.state.loading = false;
  },
  data() {
    return {};
  }
};
</script>
<style scoped>
#admin-panel {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  /* background: var(--light-gray); */
  background-image: linear-gradient(white 5%,red);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100%;
  background-size: cover;
}
</style>
